import { SkyKickUserProvider } from '@skykick/platform-identity-auth-auth0-angular';
import { BillingEnvironment } from './billing-environment';
import { defaultHotjarConfiguration } from '../app/surveys/hotjar-configuration';

export const environment: BillingEnvironment = {
  production: false,
  userProvider: SkyKickUserProvider,
  apis: {
    prefix: '/api/v1',
    sandbox: '',
    hostname: '/api',
    productCatalogHostname: 'https://sk-dev-platform-apis.skykick.com/productcatalog',
    subscriptionManagementHostname: 'https://sk-dev-platform-apis.skykick.com/subscriptionmanagement',
    acceptJS: 'https://jstest.authorize.net/v1/Accept.js',
    licensesAssignmentBaseAddress: 'https://sk-dev-platform-apis.skykick.com',
    accountingApimSuffix: 'https://sk-dev-platform-apis.skykick.com/accountingservice',
    papi: 'https://sk-dev4-papi.skykick.com'
  },
  dataDogTracing: {
    enabled: true,
    serviceName: 'skykick.web.billing',
    clientToken: 'pub92b47f986f51a3ff5bfe64890724a685',
    applicationId: 'ad865f7d-bdb0-4281-b2dc-39b6cb929ca0',
    apmTracingEnabled: true,
    defaultPrivacyView: 'mask-user-input',
    sessionSampleRate: 50,
    sessionReplaySampleRate: 50,
    environment: 'dev',
    version: '1.0.0'
  },
  dotcomRoutingHostname: 'https://sk-dev4-routing.skykick.com',
  dotdevRoutingHostname: 'https://sk-dev4-routing.skykick.dev',
  hotjarConfig: defaultHotjarConfiguration,
};
